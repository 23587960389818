<template>
  <a-input
    v-bind="$attrs"
    :value="value"
    :addon-after="addon"
    autocomplete="off"
    allow-clear
    @blur="onBlur"
    @change="onChange"
    @input="onInput"
    @keypress="numberOnly"
  />
</template>

<script>
import { useInputNumber } from '@/composable/useInputNumber'

export default {
  name: 'InputNumber',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    addon: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { numberOnly, toNumber } = useInputNumber()
    return { numberOnly, toNumber }
  },
  methods: {
    onInput(event) {
      const { value } = event.target
      const newValue = this.toNumber(value)
      const result = new Intl.NumberFormat('id-ID').format(newValue)
      this.$emit('input', result)
      if (!value) this.$emit('blur', 0)
    },
    onBlur(event) {
      this.$emit('blur', this.toNumber(event.target.value))
    },
    onChange(event) {
      this.$emit('change', this.toNumber(event.target.value))
    },
  },
}
</script>
