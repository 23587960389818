export const useInputNumber = () => {
  const numberOnly = (event) => {
    const keyCode = (event.which) ? event.which : event.keyCode
    
    if(!event.target.value && keyCode == 48) {
      event.preventDefault()
    }

    if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
      event.preventDefault()
    }
  }

  const numberDecimal = (event) => {
    const keyCode = (event.which) ? event.which : event.keyCode
    const { value } = event.target
    
    if (!value && keyCode === 46) {
      event.preventDefault()
    }

    if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || value.indexOf('.') > -1)) {
      event.preventDefault()
    }

    if (value && value.indexOf('.') > -1 && value.split('.')[1].length > 1) {
      event.preventDefault()
    }
  }

  const npwpNumber = (event) => {
    const keyCode = (event.which) ? event.which : event.keyCode
    
    if (!event.target.value && (keyCode === 45 || keyCode === 46)) {
      event.preventDefault()
    }

    if (keyCode < 45 || keyCode > 57 || keyCode === 47) {
      event.preventDefault()
    }
  }

  const toNumber = (value) => {
    if(typeof value == 'number') return value
    return Number(value.replace(/[^0-9-]+/g, ""))
  }

  return { numberOnly, numberDecimal, npwpNumber, toNumber }
}
